<template>
  <Layout>
    <div class="app-charge">
      <div class="balance-box">
        <div>当前余额</div>
        <div class="balance">{{ user.balance }}点</div>
        <div class="">充值1元，增加{{ user.point_rate }}点余额</div>
      </div>

      <a-row class="fee-list" :gutter="16">
        <a-col :span="12" v-for="(item, index) in list" :key="item.code">
          <div
            class="fee-item"
            :class="current === index ? 'active' : null"
            @click="select(index)"
          >
            <small>￥</small>{{ item.title }}
          </div>
        </a-col>
      </a-row>
      <a-button
        :disabled="!enablePay"
        @click="pay"
        size="large"
        type="primary"
        block
        style="margin-top:10px;height:44px;"
      >
        微信支付
      </a-button>

      <!-- 付款二维码 -->
      <a-modal
        :destroyOnClose="true"
        :footer="null"
        :visible="dialogVisible"
        @cancel="closeDialog"
        :bodyStyle="{
          'text-align': 'center'
        }"
      >
        <div class="title">
          微信扫码付款
        </div>
        <vue-qrcode :width="200" :value="qrcode" />
        <div>
          如果付款后长时间没有到账，请联系客服
        </div>
      </a-modal>
    </div>
  </Layout>
</template>

<script>
import { mapState } from "vuex";

import Layout from "@/layout/index.vue";

import VueQrcode from "vue-qrcode";

const OrderSvc = require("@/service/order");

export default {
  name: "Charge",

  data() {
    return {
      list: [
        { code: "add10", title: "100" },
        { code: "add11", title: "500" },

        { code: "add1", title: "1000" },
        { code: "add2", title: "2000" },
        { code: "add3", title: "3000" },
        { code: "add5", title: "5000" }
      ],
      current: -1,

      qrcode: "",
      orderId: "",
      dialogVisible: false
    };
  },

  computed: {
    ...mapState(["user", "isWeixin"]),

    enablePay() {
      return this.current >= 0;
    }
  },

  methods: {
    select(index) {
      this.current = index;
    },

    async pay() {
      if (this.current < 0) {
        return;
      }
      let isWeixin = this.isWeixin;

      const code = this.list[this.current].code;
      const res = await OrderSvc.create(code, isWeixin);
      const { code_url, id } = res;

      if (isWeixin) {
        window.WeixinJSBridge.invoke("getBrandWCPayRequest", res, res => {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            this.$message.info("充值成功");

            this.$router.go();
          }
        });
      } else {
        this.qrcode = code_url;
        this.orderId = id;

        this.dialogVisible = true;

        this.timer = setTimeout(this.checkOrderStatus, 5000);
      }
    },

    async checkOrderStatus() {
      try {
        const { status } = await OrderSvc.orderStatus(this.orderId);
        if (status === 1) {
          this.dialogVisible = false;
          this.$message.info("充值成功");
        } else {
          this.timer = setTimeout(this.checkOrderStatus, 5000);
        }
      } catch (err) {
        console.error(err);

        this.timer = setTimeout(this.checkOrderStatus, 5000);
      }
    },

    closeDialog() {
      this.dialogVisible = false;

      if (this.timer) {
        clearTimeout(this.timer);
      }
    }
  },

  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },

  components: {
    VueQrcode,
    Layout
  }
};
</script>

<style lang="scss">
.app-charge {
  width: 100%;
  height: 100vh;

  .balance-box {
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;

    .balance {
      font-size: 22px;
      color: #f00;
    }
  }

  .fee-list {
    .fee-item {
      padding: 10px;
      margin-bottom: 10px;
      border: 2px solid #eee;
      border-radius: 5px;
      cursor: pointer;

      display: flex;
      justify-content: center;
      align-items: center;

      &.active {
        border-color: #1890ff;
        color: #1890ff;
      }

      color: #000;
      font-size: 24px;

      small {
        font-size: 18px;
        text-decoration: none;
      }
    }
  }

  .bank {
    padding: 20px;
    font-size: 18px;
    margin-top: 10px;
  }
}
</style>
